<template lang="html">
    <section class="apps">
        <v-data-table class="ma-5" :headers="headers" :items="apps">
            <template v-slot:item.icon="{ item }">
                <img :src="'/storage/appIcon' + item.icon" height="50px">
            </template>
            <template v-slot:item.regen="{ item }">
                <v-btn color="primary" @click="regen(item)" text>
                    <v-icon >mdi-refresh</v-icon>
                </v-btn>
            </template>
            <template v-slot:item.apkFile="{ item }">
                <a  v-if="item.status == 'success'" :href="item.apkFile" height="50px">הורדת הקובץ</a>
            </template>
            <template v-slot:item._id="{ item }">
                <a :href="'/apps/' + item._id + '/zip'" height="50px">הורדת קוד</a>
            </template>
        </v-data-table>
    </section>
</template>

<script lang="js">
export default {
    name: 'apps',
    props: [],
    mounted() {
        this.$store.dispatch('loadApps')
    },
    data() {
        return {
            headers: [{
                text: "אייקון",
                value: 'icon',
            }, {
                text: "שם האפליקציה",
                value: 'appName',
            }, {
                text: "כתובת",
                value: 'address',
            }, {
                text: "מזהה",
                value: 'appId',
            },{
                text: "צור מחדש",
                value: 'regen',

            },{
                text: "סטאטוס",
                value: 'status',
            },{
                text: "קובץ",
                value: 'apkFile',
            }, {
                text: "קוד",
                value: '_id',
            },{
                text: "שגיאה",
                value: 'error',
            }, ]
        }
    },
    methods: {
        regen(item) {
            this.$store.dispatch('regenApp', item._id)
        }
    },
    computed: {
        apps() {
            return this.$store.getters.apps
        }
    }
}
</script>

<style scoped>
.apps {}
</style>
