<template lang="html">
    <section class="login">
        <v-row class="form justify-center ">
            <v-col md="6" class="login-column">
                <v-card class="pa-5">
                    <VCardTitle primary-title>התחברות</VCardTitle>
                    <v-form ref="loginform" v-model="valid" lazy-validation>
                        <v-alert type="error" v-if="error">{{('הפרטים_לא_נכונים')}}</v-alert>

                        <v-text-field v-model="loginForm.email" :label="('אימייל')" :rules="emailRules" type="email" required append-icon="fa fa-envelope"></v-text-field>

                        <v-text-field  v-model="loginForm.password" :label="('סיסמא')" :rules="requireRule" type="password" required append-icon="fa fa-lock"></v-text-field>


                        <v-btn block  color="primary" @click="login">{{('התחבר')}}
                            <v-icon small right>fa fa-key</v-icon>
                        </v-btn>

                    </v-form>
                </v-card>

                <v-card class="pa-5">
                    <VCardTitle primary-title>הרשמה</VCardTitle>

                    <v-form ref="registerform" v-model="valid" lazy-validation>
                        <v-text-field v-model="registerForm.email" :label="('אימייל')" :rules="emailRules" type="email" required append-icon="fa fa-envelope"></v-text-field>
                        <v-text-field v-model="registerForm.password" :label="('סיסמא')" :rules="requireRule" type="password" required append-icon="fa fa-key"></v-text-field>
                        <v-text-field v-model="confirmPassword" :label="('אימות_סיסמא')" :rules="passwordRule" type="password" required append-icon="fa fa-lock"></v-text-field>


                        <v-btn block color="secondary" @click="signup">{{('הרשמה')}}<span> <v-icon small right>fa fa-edit</v-icon></span></v-btn>
                    </v-form>
                </v-card>
            </v-col>
        </v-row>
    </section>
</template>

<script lang="js">
export default {
    name: 'login',
    props: [],
    mounted() {

    },
    data() {
        return {

            error: false,
            loginForm: {},
            registerForm: {},
            valid: false,
            confirmPassword: "",
            requireRule: [
                v => !!v || ('שדה_זה_חובה')
            ],
            emailRules: [
                v => !!v || ('אימייל_שדה_חובה'),
                v => /.+@.+\..+/.test(v) || ('אימייל_לא_תקין'),
            ],
            passwordRule: [
                v => v == this.registerForm.password || ('סיסמאות_לא_תואמות')
            ],
        }
    },
    methods: {
        login() {
            if (!this.$refs.loginform.validate()) return;
            this.$store.dispatch("login",this.loginForm);
        },
        signup() {
            if (!this.$refs.registerform.validate()) return;
            this.$store.dispatch("register",this.registerForm);
        },
    },
    computed: {

    }
}
</script>

<style scoped>
.login-column {
    justify-content: space-evenly;
    height: 90vh;
    display: flex;
    flex-direction: column;
}
</style>
